import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme'
import { AnimatePresence, motion } from 'framer-motion'
import React, { memo, ReactNode, useEffect } from 'react'

interface Props {
  children: ReactNode
  location: any
}

export const Transition = memo(function Transition({
  children,
  location,
}: Props) {
  useEffect(() => {
    history.scrollRestoration = 'manual'
  }, [])

  return (
    <AnimatePresence mode="wait">
      <Main key={location.pathname}>
        <Cover
          initial={{ y: '-100%' }}
          animate={{ y: '-100%' }}
          exit={{ y: 0 }}
          transition={{ duration: 0.7, ease: [0.645, 0.045, 0.355, 1.0] }}
        />
        <Uncover
          initial={{ y: 0 }}
          animate={{ y: '100%' }}
          exit={{ y: '100%' }}
          transition={{
            duration: 0.7,
            ease: [0.645, 0.045, 0.355, 1.0],
            delay: 0.2,
          }}
        ></Uncover>
        {children}
      </Main>
    </AnimatePresence>
  )
})

const Main = styled(motion.main)``

const Style = css`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  position: fixed;
  background: ${theme.colors.variants.primaryDark};
`

const Line = styled(motion.svg)`
  position: absolute;
  top: 75%;
  right: 0;
  width: 100%;
  height: auto;
  opacity: 0.6;
  stroke-dasharray: 1500;
  stroke-dashoffset: 0;
  transform: translateY(-50%);

  &:first-of-type {
    top: 25%;
    left: 0;
  }

  path {
    stroke-width: 2px;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight3};
  }

  @media (max-width: 991px) {
    top: 60%;
    &:first-of-type {
      top: 40%;
      left: 0;
    }
  }
`

const Cover = styled(motion.aside)`
  ${Style}
`
const Uncover = styled(motion.aside)`
  ${Style}

  ${Line} {
    stroke-dashoffset: -1500;
  }
  ${Line}:first-of-type {
    stroke-dashoffset: 1500;
  }
`
